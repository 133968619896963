<template>
  <el-drawer :title="formTitle" size="50%" :visible.sync="formVisible">
    <el-form
      :model="dataForm"
      ref="dataForm"
      label-width="120px"
      :rules="formRules"
    >
      <el-form-item label="测试项目:" prop="HurtType">
          <enumSelector2
            ref="enumSelector"
            :options="$config.kneeHurtType"
            :clearable="false"
            size="small"
            v-model="dataForm.HurtType"
          />
      </el-form-item>
      <el-form-item label="得分或扣分项:" prop="Score">
          <el-input-number
            v-model="dataForm.Score"
          ></el-input-number>
      </el-form-item>
      <el-form-item label="问题:" prop="Question">
          <el-input
            v-model="dataForm.Question"
            auto-complete="off"
            placeholder="请输入问题"
            :disabled="readOnly"
          ></el-input>
      </el-form-item>
       <el-form-item label="建议:" prop="Suggest">
          <el-input
            v-model="dataForm.Suggest"
            auto-complete="off"
            placeholder="请输入建议"
            :disabled="readOnly"
          ></el-input>
      </el-form-item>
      <el-form-item v-if="!readOnly">
        <div class="footer">
          <el-button type="primary" @click="handleSubmit">提交</el-button>
        </div>
      </el-form-item>
    </el-form>
  </el-drawer>
</template>
<script>
import { save } from "@/api/library/libKneeHurt";
import enumSelector2 from "@/components/enumSelector2";
export default {
  components: {
    enumSelector2,
  },
  props: {
  },
  data() {
    return {
      readOnly: false,
      formVisible: false,
      formTitle: "新增",
      dataForm: {
        
      },
      formRules: {
        HurtType: [
          { required: true, message: "请选择测试项目", trigger: "change" },
        ],
        Score: [
          { required: true, message: "请输入分数", trigger: "change" },
        ],
        Question: [
          { required: true, message: "请输入问题", trigger: "change" },
        ],
        Suggest: [
          { required: true, message: "请输入建议", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    handleView(row) {
      this.readOnly = true;
    },
    handleCreate() {
      this.formTitle = "新增";
      this.formVisible = true;
      this.readOnly = false;
      this.dataForm = {
       
      };
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    handleUpdate(row) {
      this.dataForm = JSON.parse(JSON.stringify(row));
      this.readOnly = false;
      this.formTitle = "修改";
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    handleSubmit: function () {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let data = Object.assign({}, this.dataForm);
          save(data).then((response) => {
            if (response) {
              this.formVisible = false;
              this.$emit("update");
            }
          });
        } else {
          setTimeout(() => {
            this.$refs["dataForm"].clearValidate();
          }, 1000);
        }
      });
    },
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
/deep/.el-drawer__header {
  margin-bottom: 10px;
  font-size: 16px;
  color: #ff862f;
}
/deep/.el-drawer__body {
  overflow-y: scroll;
}
.footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
.invoice-container {
  padding: 10px;
  .input {
    width: 150px;
  }
  .tabs-container {
    /deep/.el-tabs--border-card > .el-tabs__content {
      padding: 0;
      height: 1px;
    }
    .tab-content {
      margin-top: -2px;
      border: 1px solid #dcdfe6;
      border-top: 0;
      padding: 15px;
      background: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    }
  }
}
</style>
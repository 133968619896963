var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: { title: _vm.formTitle, size: "50%", visible: _vm.formVisible },
      on: {
        "update:visible": function($event) {
          _vm.formVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            "label-width": "120px",
            rules: _vm.formRules
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "测试项目:", prop: "HurtType" } },
            [
              _c("enumSelector2", {
                ref: "enumSelector",
                attrs: {
                  options: _vm.$config.kneeHurtType,
                  clearable: false,
                  size: "small"
                },
                model: {
                  value: _vm.dataForm.HurtType,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "HurtType", $$v)
                  },
                  expression: "dataForm.HurtType"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "得分或扣分项:", prop: "Score" } },
            [
              _c("el-input-number", {
                model: {
                  value: _vm.dataForm.Score,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "Score", $$v)
                  },
                  expression: "dataForm.Score"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "问题:", prop: "Question" } },
            [
              _c("el-input", {
                attrs: {
                  "auto-complete": "off",
                  placeholder: "请输入问题",
                  disabled: _vm.readOnly
                },
                model: {
                  value: _vm.dataForm.Question,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "Question", $$v)
                  },
                  expression: "dataForm.Question"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "建议:", prop: "Suggest" } },
            [
              _c("el-input", {
                attrs: {
                  "auto-complete": "off",
                  placeholder: "请输入建议",
                  disabled: _vm.readOnly
                },
                model: {
                  value: _vm.dataForm.Suggest,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "Suggest", $$v)
                  },
                  expression: "dataForm.Suggest"
                }
              })
            ],
            1
          ),
          !_vm.readOnly
            ? _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "footer" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleSubmit }
                      },
                      [_vm._v("提交")]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }